import { Component, Input } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-video-body',
  templateUrl: './video-body.component.html',
  styleUrls: ['./video-body.component.scss'],
})
export class VideoBodyComponent {
  @Input() text = 'Plataforma para la gestión de Bienes y Servicios';
  videoAgd = environment.VIDEO_INTRO_AGD;
}
