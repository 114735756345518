import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dialog-update-new-version',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule],
  templateUrl: './dialog-update-new-version.component.html',
  styleUrl: './dialog-update-new-version.component.scss',
})
export class DialogUpdateNewVersionComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogUpdateNewVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: void
  ) {}

  close() {
    this.dialogRef.close(true);
  }
}
