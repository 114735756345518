import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { ConstToastType } from '@interfaces/toast.interfaces';
import { ToastService } from '@services/toast/toast.service';
import { DialogUpdateNewVersionComponent } from '@sharedComponents/dialogs/dialog-update-new-version/dialog-update-new-version.component';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewVesionCheckerService {
  private currentRequest: Subscription | null = null;
  intervalSubscription: Subscription | null = null;
  isNewVersionAvailable: boolean = false;
  intervalSource = interval(30 * 1000);

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly toastService: ToastService,
    private readonly dialog: MatDialog,
    private readonly zone: NgZone
  ) {}

  checkForUpdate(): void {
    this.intervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      this.intervalSubscription = this.intervalSource.subscribe(async () => {
        try {
          this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
          if (this.isNewVersionAvailable) {
            this.openDialogUpdateVersion();
          }
        } catch (error) {
          console.error('Failed to check for updates:', error);
        }
      });
    });
  }

  public checkUpdate(): void {
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
    }

    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.currentRequest = this.swUpdate.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_DETECTED') {
        this.toastService.show('Nueva version detectada', ConstToastType.warning);
      }

      if (event.type === 'VERSION_READY') {
        this.openDialogUpdateVersion();
      }

      if (event.type === 'VERSION_INSTALLATION_FAILED') {
        this.toastService.show('Fallo la instalación de la nueva version', ConstToastType.warning);
      }
    });
  }

  public openDialogUpdateVersion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.backdropClass = 'backdrop';
    dialogConfig.height = '258.297px';
    dialogConfig.width = '450px';
    this.dialog
      .open(DialogUpdateNewVersionComponent, dialogConfig)
      .afterClosed()
      .subscribe(x => {
        this.updateVersion();
      });
  }

  private updateVersion() {
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch(error => console.error('Failed to apply updates:', error));
  }
}
