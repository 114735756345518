<app-video-body>
  <div class="container">
    <h2>Ingrese su nueva contraseña</h2>
    <form class="form" (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
      <mat-form-field appearance="outline">
        <mat-label class="label">Nueva contraseña</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          [required]="true"
          formControlName="PASSWORD"
          id="PASSWORD"
        />
        <button
          type=""
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          class="buttonIcon"
        >
          <mat-icon class="visibility">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="label">Repita su contraseña</mat-label>
        <input
          matInput
          [type]="hide1 ? 'password' : 'text'"
          required
          formControlName="PASSWORD_CONFIRMATION"
          autocomplete="off"
        />
        <button
          type=""
          mat-icon-button
          matSuffix
          (click)="hide1 = !hide1"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide1"
          class="buttonIcon"
        >
          <mat-icon class="visibility">{{
            hide1 ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <div class="requirement">
      <div
        [ngClass]="{
          'red-text': resetPasswordForm.hasError('minlength', 'PASSWORD'),
          'green-text': !resetPasswordForm.hasError('minlength', 'PASSWORD'),
        }"
      >
        La contraseña debe tener al menos 10 caracteres.
      </div>
      <div
        [ngClass]="{
          'red-text': resetPasswordForm.hasError(
            'missingUpperCase',
            'PASSWORD'
          ),
          'green-text': !resetPasswordForm.hasError(
            'missingUpperCase',
            'PASSWORD'
          ),
        }"
      >
        La contraseña debe contener al menos una letra mayúscula.
      </div>
      <div
        [ngClass]="{
          'red-text': resetPasswordForm.hasError(
            'missingLowerCase',
            'PASSWORD'
          ),
          'green-text': !resetPasswordForm.hasError(
            'missingLowerCase',
            'PASSWORD'
          ),
        }"
      >
        La contraseña debe contener al menos una letra minúscula.
      </div>
      <div
        [ngClass]="{
          'red-text': resetPasswordForm.hasError('missingNumber', 'PASSWORD'),
          'green-text': !resetPasswordForm.hasError(
            'missingNumber',
            'PASSWORD'
          ),
        }"
      >
        La contraseña debe contener al menos un número.
      </div>
      <div
        [ngClass]="{
          'red-text': resetPasswordForm.hasError('missingSymbol', 'PASSWORD'),
          'green-text': !resetPasswordForm.hasError(
            'missingSymbol',
            'PASSWORD'
          ),
        }"
      >
        La contraseña debe contener al menos un símbolo.
      </div>
      <div
        [ngClass]="{
          'red-text': resetPasswordForm.hasError('passwordMismatch'),
          'green-text': !resetPasswordForm.hasError('passwordMismatch'),
        }"
      >
        Las contraseñas deben coincidir
      </div>
    </div>
    <button
      (click)="onSubmit()"
      type="submit"
      class="btn-dialog-v6"
      [disabled]="resetPasswordForm.invalid"
      style="height: 50px; cursor: pointer"
    >
      ENVIAR
    </button>
    <p>
      Una vez terminado, podrá ingresar con su nueva contraseña desde el inicio
    </p>
    <a routerLink="/login">Regresar a Iniciar sesión</a>
  </div>
</app-video-body>
