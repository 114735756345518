<div class="body-card-container-dialog-warning" style="gap: 15px">
  <div class="tittle-custom-dialog-warning"></div>
  <div class="custom-dialog-warning-icon">
    <mat-icon class="icon-action-danger">error</mat-icon>
  </div>
  <div
    class="custom-dialog-warning-body"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
    "
  >
    <div class="color-title">Nueva version detectada</div>
    <div class="color-subtitle">
      Es necesario actualizar la vista<br />
      Presione el boton para continuar
    </div>
  </div>
  <div class="button-container-warning">
    <div>
      <button class="btn-dialog-v8" (click)="close()">Actualizar</button>
    </div>
  </div>
</div>
